import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  LoadingController,
  ToastController,
  AlertController
} from '@ionic/angular';
import { headersToString } from 'selenium-webdriver/http';

export interface Msg {
  content: string;
  style: string;
}
@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {}

  private _msgSource = new Subject<Msg>();
  msg = this._msgSource.asObservable();

  public asyc;
  update(content: string, style: string) {
    const msg: Msg = { content, style };
    this._msgSource.next(msg);
    console.log(msg);
  }

  clear() {
    this._msgSource.next();
  }
  async presentLoading(msg?, spinner?) {
    const loading = await this.loadingCtrl.create({
      animated: true,
      spinner: spinner || 'bubbles',
      message: msg || 'Loading...'
    });

    loading.present();
  }

  async dismisLoading() {
    await this.loadingCtrl.dismiss().catch();
  }

  async presentAlert(msg: string, hdr: string) {
    const alert = await this.alertCtrl.create({
      animated: true,
      header: hdr,
      message: msg,
      subHeader: '',
      buttons: [
        {
          text: 'Close',
          role: 'Destructive',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  async presentPrompt(msg: string, hdr: string) {
    const alert = await this.alertCtrl.create({
      header: hdr,
      message: msg,
      animated: true,
      buttons: [
        {
          text: 'Cancel',
          role: 'destructive',
          handler: () => {
            return { state: false };
          }
        },
        {
          text: 'Continue',
          handler: () => {
            return { state: true };
          }
        }
      ]
    });

    await alert.present();

    return alert
      .onDidDismiss()
      .then(d => d)
      .catch(e => e);
  }

  async presentToast(
    msg: any,
    color: string,
    pos?: 'top' | 'bottom' | 'middle'
  ) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color,
      position: pos || 'top'
    });
    await toast.present();
  }
}
