import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public tripData: any;
  constructor(private plt: Platform) {}

  isCordova(): boolean {
    return this.plt.is('android') || this.plt.is('cordova');
  }
  // JSON "set" example
  async setObject(data: {name: string, value: any}) {
    await Storage.set({
      key: data.name,
      value: JSON.stringify(data.value),
    });
  }

  // JSON "get" example
  async getObject(songs: string) {
    const ret = await Storage.get({ key: songs });
    return JSON.parse(ret.value);
  }

  async setItem() {
    await Storage.set({
      key: 'name',
      value: 'Max',
    });
  }

  async getItem() {
    const { value } = await Storage.get({ key: 'name' });
    console.log('Got item: ', value);
  }

  async removeItem() {
    await Storage.remove({ key: 'name' });
  }

  async keys() {
    const { keys } = await Storage.keys();
    console.log('Got keys: ', keys);
  }

  async clear() {
    await Storage.clear();
  }
}
