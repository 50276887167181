// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBg5uPloc9pfSa_gkZ9vah1Js1YRoW9oKA',
    authDomain: 'campus-hub.firebaseapp.com',
    databaseURL: 'https://campus-hub.firebaseio.com',
    projectId: 'firebase-campus-hub',
    storageBucket: 'firebase-campus-hub.appspot.com',
    messagingSenderId: '1048962797634',
    appId: '1:1048962797634:web:6432b596456e475a721ddb',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
