import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { NotifyService } from '../notify/notify.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { isEmpty } from 'lodash';
import { HttpClient } from '@angular/common/http';
const SONGS_KEY = 'songs';

const CACHE_SIZE = 1;
const regExp = /[a-zA-Z]/g;
@Injectable({
  providedIn: 'root',
})
export class SongsService {
  private songsCache$: Observable<any>;
  song: Observable<any>;
  songToEdit: Observable<any>;
  songsList: Observable<any>;
  songSlides: Array<any> = [];
  constructor(
    private api: ApiService,
    private notify: NotifyService,
    private storage: LocalStorageService,
    private http: HttpClient
  ) {}

  getsongToEdit(id) {
    if (!this.songToEdit) {
      return this.getSong(id);
    }
    return this.songToEdit;
  }

  editSong(song) {
    this.api.update(`songsOfWorship/${song.id}`, song).then(() => {
      this.notify.presentToast(`${song.title} update`, 'success');
    });
  }

  async getSong(id: any) {
    if (regExp.test(id)) {
      /* do something if letters are found in your string */
      const songs = (await this.storage.getObject(SONGS_KEY)) as Array<any>;

      if (songs) {
        const findSong = songs.find((song) => song.id === id);
        if (!isEmpty(findSong)) {
          return of(findSong);
        }
      }
      return this.api.doc$(`songsOfWorship/${id}`);
    } else {
      return this.api.colWithIds$(`songsOfWorship`, (ref) => {
        return ref.where('number', '==', +id).limit(1);
      });
    }
  }

  deleteSong(song) {
    this.api.delete(`songsOfWorship/${song.id}`);
    this.notify.presentToast(`${song.title} deleted`, 'success');
  }
  get getSongs() {
    if (!this.songsCache$) {
      this.songsCache$ = this.getSongList().pipe(shareReplay(CACHE_SIZE));
    }
    return this.songsCache$;
  }

  set _songToEdit(song) {
    this.songToEdit = song;
  }

  // getsongToEdit(id): Observable<any> {
  //   if (!this.songToEdit) {
  //     return this.getSong(id);
  //   }
  //   return this.songToEdit;
  // }

  songContent(id) {
    if (!this.songsCache$) {
      this.songsCache$ = this.getSongs;
    }
    return this.songsCache$.pipe(filter((value) => value.id === id));
  }

  private getSongList(): Observable<any> {
    this.songsList = this.api.colWithIds$('songsOfWorship', (ref) => {
      const query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;
      return query.orderBy('number', 'asc');
    });

    // this.songsList = this.songsList.pipe(
    //   map(    data => {
    //     if (this.songSlides.length === 0){
    //        this.getAllSlides().then(res => {
    //          console.log(res);
    //        });
    //     }else{
    //       for (const song in data){
    //         if (song){
    //           const songDetails = data[song];
    //           const songNumber = songDetails.number;
    //           // tslint:disable-next-line:radix
    //           const slide = this.songSlides.find((s => parseInt(s.number) === songNumber));
    //           // tslint:disable-next-line
    //           data[song]['slides'] = slide;
    //         }
    //       }
    //     }
    //
    //     return data;
    //   })
    // );
    this.songsList.subscribe((songs: Array<any>) => {
      const data = { name: SONGS_KEY, value: songs };
      // console.log(songs);

      this.storage.setObject(data);
    });
    return this.songsList;
  }

  localFiles(songNumber) {
    this.api.get(songNumber).subscribe((rep) => {
      console.log(rep);
    });
  }

  getAllSlides(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.api
        .post('allSongs.php', { id: '' })
        .subscribe((resp: Array<any>) => {
          const slides = resp as object;
          for (const songNumber in slides) {
            if (slides.hasOwnProperty(1)) {
              const songSlides = slides[songNumber] as Array<any>;
              const urlLink = [];
              songSlides.forEach((song) => {
                urlLink.push({
                  url: (
                    'https://worshipsongs.jerrymattix.com/api/songs/' +
                    songNumber +
                    '/' +
                    song
                  ).trim(),
                  fileName: '../../../assets/songs/' + songNumber + '/' + song,
                });
              });

              this.songSlides.push({
                number: songNumber,
                slides: urlLink,
              });
            }
          }

          resolve(this.songSlides);
        });
    });
  }

  getSlides(songNumber): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .post('getSongSlides.php', {
          songNumber,
        })
        .subscribe(
          (resp) => {
            if (resp === 'noDir') {
              return resolve({
                status: false,
                data: 'noFound',
              });
            }

            if (resp === 'noFiles') {
              return resolve({
                status: false,
                data: 'noFiles',
              });
            }

            const slidesArray = [];
            const slides = resp as object;
            // tslint:disable-next-line:forin
            for (const slide in slides) {
              slidesArray.push({
                url: (
                  'https://worshipsongs.jerrymattix.com/api/' +
                  songNumber +
                  '/' +
                  slides[slide]
                ).trim(),
                fileName:
                  '../../../assets/songs/' + songNumber + '/' + slides[slide],
              });
            }

            resolve({
              status: true,
              data: slidesArray,
            });
          },
          (error) => {
            reject({
              status: false,
              data: 'httpError',
            });
          }
        );
    });
  }

  updateSong(songNumber: number, id: string) {}

  addSong(song) {
    // check authstate;
    return new Promise((resolve, reject) => {
      return this.api
        .add('songsOfWorship', song)
        .then(() => {
          this.notify.presentToast('Song ADDED', 'success');
          return resolve(true);
        })
        .catch((e) => {
          return reject(false);
        });
    });
  }

  loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = url || '';
    });
  }
  toBase64(file: File): Promise<string | { error: any }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject({ error });
    });
  }
  getPassScript(scripture = 'James') {
    return this.http.get('http://getbible.net/json?p=' + scripture);
  }
}

// RewriteBase /
//   RewriteRule ^ index\.html$ - [L]
// RewriteCond % { REQUEST_FILENAME }! - f
// RewriteCond % { REQUEST_FILENAME }! - d
// RewriteRule. / index.html[L]
