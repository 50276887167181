import { Injectable } from '@angular/core';
import {
  Action,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
} from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';

import { map, take, tap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;
const url = 'https://worshipsongs.jerrymattix.com/api';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public afs: AngularFirestore,
    public auth: AngularFireAuth,
    private cloudFunctions: AngularFireFunctions,
    private http: HttpClient
  ) {}

  // get(endpoint: string, params?: any, reqOpts?: any) {
  //   if (!reqOpts) {
  //     reqOpts = {
  //       params: new HttpParams()
  //     };
  //   }

  //   // Support easy query params for GET requests
  //   if (params) {
  //     reqOpts.params = new HttpParams();
  //     for (const k in params) {
  //       if (k) {
  //         reqOpts.params = reqOpts.params.set(k, params[k]);
  //       }
  //     }
  //   }

  //   return this.http.get(`${this.url}/${endpoint}`, reqOpts);
  // }

  get token(): any {
    return firebase.default
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return token;
      });
  }

  get timestamp() {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }

  // put(endpoint: string, body: any, reqOpts?: any) {
  //   return this.http.put(`${this.url}/${endpoint}`, body, reqOpts);
  // }

  // delete(endpoint: string, reqOpts?: any) {
  //     return this.http.delete(`${this.url}/${endpoint}`, reqOpts);
  // }

  // patch(endpoint: string, body: any, reqOpts?: any) {
  //   return this.http.patch(`${this.url}/${endpoint}`, body, reqOpts);
  // }

  async getAuthUid() {
    return (await this.auth.currentUser.then()).uid;
  }

  get(endpoint: string): Observable<any> {
    return this.http.get(`../../../assets/songs/${endpoint}/`);
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post(`${url}/${endpoint}`, JSON.stringify(body));
  }

  /// Firebase Server Timestamp
  loginWithEmail(email, password) {
    return firebase.default.auth().signInWithEmailAndPassword(email, password);
  }

  async httpHeader() {
    const token = await this.token;
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Firebase-ID-Token': token,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }),
    };
    return httpOptions;
  }

  checkAuth() {
    return this.auth.authState;
  }

  signInUser(data): Promise<any> {
    return this.auth.signInWithEmailAndPassword(data.email, data.password);
  }

  /// **************
  /// Get a Reference
  /// **************

  callAbleFunction(functionName: string, param: any) {
    const test = this.cloudFunctions.httpsCallable(`${functionName}`);
    return test(`${param}`);
  }

  callHttpFunction(name: string, param: any, header): Observable<any> {
    return this.http.post(name, param, header);
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return doc.payload.data() as T;
        })
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs) => {
          return docs.map((a) => a.payload.doc.data()) as T[];
        })
      );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            // tslint:disable-next-line:ban-types
            const data: Object = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  /// **************
  /// Write Data
  /// **************

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        })
      )
      .subscribe();
  }

  set<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp;
    return this.doc(ref).set(
      {
        ...data,
        updatedAt: timestamp,
        createdAt: timestamp,
      },
      { merge: true }
    );
  }

  update<T>(ref: DocPredicate<T>, data: any) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp,
    });
  }

  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  add<T>(
    ref: CollectionPredicate<T>,
    data
  ): Promise<firebase.default.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp,
    });
  }

  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();
    return doc.then(
      (
        snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>
      ) => {
        return snap.payload.exists
          ? this.update(ref, data)
          : this.set(ref, data);
      }
    );
  }

  runEngageDriverTransaction(driverId: string) {
    const timestamp = this.timestamp;
    const collection = firebase.default
      .firestore()
      .collection('available-drivers');
    const document = collection.doc(`${driverId}`);

    return firebase.default.firestore().runTransaction((transaction) => {
      return transaction.get(document).then((doc) => {
        const data = doc.data();
        const engageDriver = true;
        if (data.engaged) {
          return false;
        } else {
          transaction.update(document, {
            engaged: engageDriver,
            updatedAt: timestamp,
          });
          return true;
        }

        // return transaction.set(newRatingsDoc, ratings);
      });
    });
  }

  // not used yet
  batchWrite(ref, data: any) {
    const timestamp = this.timestamp;
    const batch = firebase.default.firestore().batch();

    const orderItems = [];
    data.forEach((e) => {
      e.updatedAt = timestamp;
      e.createdAt = timestamp;
      orderItems.push({
        docRef: firebase.default.firestore().collection(`orders`).doc(),
        data: e,
      });
    });
    orderItems.forEach((e) => {
      batch.set(e.docRef, e.data);
    });

    return batch.commit();
  }
}
