import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'songs',
    pathMatch: 'full',
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./pages/songs/songs.module').then((m) => m.SongsPageModule),
  },
  {
    path: 'songs',
    loadChildren: () =>
      import('./pages/songs/songs.module').then((m) => m.SongsPageModule),
  },
  {
    path: 'song/:id',
    loadChildren: () =>
      import('./pages/song/song.module').then((m) => m.SongPageModule),
  },
  {
    path: 'add-song',
    loadChildren: () =>
      import('./pages/add-song/add-song.module').then(
        (m) => m.AddSongPageModule
      ),
  },
  {
    path: 'edit-song/:songId',
    loadChildren: () =>
      import('./pages/add-song/add-song.module').then(
        (m) => m.AddSongPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
