import { Component, OnInit } from '@angular/core';
import { SongsService } from './services/songs/songs.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home', custom: false },
    {
      title: 'Songs of Worship',
      url: '/songs',
      icon: 'musical-note',
      custom: false,
    },
    {
      title: 'Prayer Requests',
      url: '/prayer-requests',
      icon: '../assets/custom-ion-icons/prayer.svg',
      custom: true,
    },
    {
      title: 'Sermon Archive',
      url: '/sermon-archive',
      icon: 'heart',
      custom: false,
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private songService: SongsService) {}

  ngOnInit() {
    // this.songService.getAllSlides();
  }
}
